import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Contact from "../components/contact"
import Footer from "../components/footer"

const NotFoundPage = () => {
  return (
    <div>
      <Seo title={"404: Not found"}/>
      <Navbar/>
      <div className="notfound__container bd-container">
          <div className="notfound">
            <h1 className="notfound__title">404</h1>
            <h1 className="notfound__subtitle">PAGE NOT FOUND</h1>
            <Link to="/" className="notfound__button">Back to Homepage</Link>
          </div>
      </div>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default NotFoundPage
